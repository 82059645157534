import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  private testnomialsPath: string = 'assets/images/Testimonials/';
  allTestimonials: (string)[] = [];
  mainImages: (string)[] = [];
  height: string = '400px';
  fullscreen: boolean = false;
  public path: string = 'assets/images/Dashboard/';
  public projectData: Array<any>;
  imgesss: string[] = ['assets/images/Scroll/001.jpg', 'assets/images/Scroll/02.jpg'];

  constructor(private router: Router) { }

  ngOnInit() {
    this.loadImages();
    this.allCompletedProjects();
  }

  public loadImages() {
    this.allTestimonials = [this.testnomialsPath + 'jpsir.jpg',
    this.testnomialsPath + 'Jd.png',
    this.testnomialsPath + 'Ikkurthi.png']

    this.mainImages = [
      "assets/images/HomeScrollImages/2.png",
      "assets/images/HomeScrollImages/3.JPG",
      "assets/images/HomeScrollImages/4.jpg",
      "assets/images/HomeScrollImages/5.png"
    ];
  }

  public allCompletedProjects() {

    this.projectData = [
      {
        imageUrl: this.path + '01.jpg',
        projectTitle: 'Project 1: Upper Primary School Chinnanagaram (V),Nallikudur (M), Warangal District, Telangana',
        date: '18/08/2019',
        id: "Chinnanagaram"
      },
      {
        imageUrl: this.path + 'jajapur.jpg',
        projectTitle: 'Project 2: Mandal Parishad Primary School Jajapur (V),Narayanpet (M), Mahabubnagar District, Telangana',
        date: '18/08/2019',
        id: "Jajapur"
      },
      {
        imageUrl: this.path + 'garakurthi.jpg',
        projectTitle: 'Project 3: Mandal Parishad Primary School Garakurthi (V),kondapur (M), Sangareddy District, Telangana',
        date: '18/08/2019',
        id: "Garakurthi"
      },
      {
        imageUrl: this.path + 'malkeezguda.jpg',
        projectTitle: 'Project 4 : Mandal Parishad Primary School Malkeezguda (V),Yacharam (M), Rangareddy District, Telangana',
        date: '18/08/2019',
        id: "Malkeezguda"
      },
      {
        imageUrl: this.path + 'cherlapalem.jpg',
        projectTitle: 'Project 5 : Zilla Parishad High School Cherlapalem(V),Thorrur (M), Mahabubabad District, Telangana',
        date: '18/08/2019',
        id: "Cherlapalem"
      },
      {
        imageUrl: this.path + 'vutkur.jpg',
        projectTitle: 'Project 6: Upper Primary School Vutkur,Nidamanoor Mandal, Nalgonda District, Telangana',
        date: '18/08/2019',
        id: "Vutkur"
      },
      {
        imageUrl: this.path + 'ananthasagar.jpg',
        projectTitle: 'Project 7: Mandal Parishad Upper Primary School Anathasagar, Medak District, Telangana',
        date: '18/08/2019',
        id: "Anathasagar"
      }
    ]
  }

  public forMoreProjects() {
    this.router.navigate(['/projects']);
  }

  public projectDetails(selectedPro: string) {
    localStorage.setItem("selectedProject", selectedPro);
    this.router.navigate(['/projects']);
  }

  public doPayment() {
    window.open(environment.paymentUrl);;
  }
}
