import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coreteam',
  templateUrl: './coreteam.component.html',
  styleUrls: ['./coreteam.component.css']
})
export class CoreteamComponent implements OnInit {

  public chiefPatron: Array<any> = [];
  public advisoryCouncilMembers: Array<any> = [];
  public coreCommitteeMembers: Array<any> = [];
  public chiefPatronTitle: string = 'Chief Patron';
  public advisoryCouncilTitle: string = 'Advisory Council Members';
  public coreCommitteeTitle: string = 'Core Committee Members';

  constructor() { }
  ngOnInit() {
    this.getallImagesData();
  }

  public getallImagesData() {
    let imgPathChiefPatron = 'assets/images/Team/CoreTeam/ChiefPatron/';
    let imgPathadvisory = 'assets/images/Team/CoreTeam/advisors/';
    let imgPathCore = 'assets/images/Team/CoreTeam/CoreComittee/';
    let jpg = '.jpg';
    let png = '.png';
    let gif = '.gif';

    this.chiefPatron = [
      {
        imageUrl: imgPathChiefPatron + 'JayaprakashNarayan' + jpg,
        imageTitle: "Dr. JayaPrakash Narayanan, IAS"
      },
      {
        imageUrl: imgPathChiefPatron + 'JD_Lakshmi_NarayanaIPS' + jpg,
        imageTitle: "JD Lakshmi Narayana, IPS"
      }
    ];
    this.advisoryCouncilMembers = [
      {
        imageUrl: imgPathadvisory + 'balachandar.jpeg',
        imageTitle: "Balachandra Sunku"
      },
      {
        imageUrl: imgPathadvisory + 'lakshi_narasimha' + jpg,
        imageTitle: "Lakshi Narasimha Ikkurthi"
      },
      {
        imageUrl: imgPathadvisory + 'thirupathi.jpeg',
        imageTitle: "Anumandla Thirupathi Reddy"
      },
      {
        imageUrl: imgPathadvisory + 'durga.jpeg',
        imageTitle: "Durga Kalyani G"
      }
    ];
    this.coreCommitteeMembers = [
      {
        imageUrl: imgPathCore + '2Madhukar.JPG',
        imageTitle: "Madhukar Kolam",
        designation: "President"
      },
      {
        imageUrl: imgPathCore + 'amruth.jpeg',
        imageTitle: "Amruth Chinthala",
        designation: "General Secretary"
      },
      {
        imageUrl: imgPathCore + '3Shireesha.jpg',
        imageTitle: "Shireesha Pochamalla",
        designation: "Treasurer"
      },
      {
        imageUrl: imgPathCore + '4VarunRaj.jpeg',
        imageTitle: "Varun Raj Enugala",
        designation: "Vice-President"
      },
      {
        imageUrl: imgPathCore + '5RamPrasad' + '.jpeg',
        imageTitle: "Ram Prasad",
        designation: "Joint Secretary"
      },
      {
        imageUrl: imgPathCore + 'bharath.jpeg',
        imageTitle: "Bharath Kalakoti",
        designation: "Executive Member"
      }
    ]
  }
}
