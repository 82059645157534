import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { DonateusComponent } from './donateus/donateus.component';
import { PrintmediaComponent } from './printmedia/printmedia.component';
import { JoinusComponent } from './joinus/joinus.component';
import { AllprojectsComponent } from './allprojects/allprojects.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CoreteamComponent } from './coreteam/coreteam.component';
import { PartnersComponent } from './partners/partners.component';
import { FounderstoryComponent } from './founderstory/founderstory.component';
import { PaymentscreenComponent } from './paymentscreen/paymentscreen.component';


const routes: Routes = [
  {path:'home', component: HomeComponent},
  {path:'aboutus', component: AboutusComponent},
 {path:'coreteam', component: CoreteamComponent},
 {path:'partners', component: PartnersComponent},
 {path:'founderStory', component: FounderstoryComponent},
 {path:'printMedia', component: PrintmediaComponent},
 {path:'newsLetters', component: NewslettersComponent},
 {path:'allProjects', component: AllprojectsComponent},
 {path:'donateUs', component: DonateusComponent},
 {path:'joinUs', component: JoinusComponent},
 {path:'projects', component: ProjectsComponent},
 {path:'paymentscreen', component: PaymentscreenComponent},
{
  path:'**',
  redirectTo: '/home'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
