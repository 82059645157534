import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { PaymentService } from '../payment.service'
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { env } from 'process';

@Component({
  selector: 'app-donateus',
  templateUrl: './donateus.component.html',
  styleUrls: ['./donateus.component.css']
})
export class DonateusComponent implements OnInit {
  public amount: number;
  public fullname: string;

  constructor(private paymentservice: PaymentService, private router: Router) { }

  ngOnInit() {
  }

  public redirectToPaytm(){
   this.paymentservice.initiatePayment(this.amount).subscribe((result)=>this.redirectToPayment(result),
   (error)=>this.onfail("Something went wrong!"));
  }

  private redirectToPayment(result){
    window.open().document.write(result);
  }

  private onfail(message){
    //alert(message);
  }

  public doPayment(){
    window.location.href = environment.paymentUrl;
  }
}
