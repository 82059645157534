import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allprojects',
  templateUrl: './allprojects.component.html',
  styleUrls: ['./allprojects.component.css']
})
export class AllprojectsComponent implements OnInit {

  path: string = 'assets/images/Dashboard/';
  projectData: Array<any>;
  dvCssStyle: string = "hideGallery";
  galleryData: Array<any>;

  constructor(private router: Router) { }

  ngOnInit() {
    this.allCompletedProjects();
  }

  public allCompletedProjects() {

    this.projectData = [
      {
        imageUrl: this.path + '01.jpg',
        projectTitle: 'Project 1: Upper Primary School Chinnanagaram (V),Nallikudur (M), Warangal District, Telangana',
        date: '15/08/2016',
        id: "Chinnanagaram"
      },
      {
        imageUrl: this.path + 'jajapur.jpg',
        projectTitle: 'Project 2: Mandal Parishad Primary School Jajapur (V),Narayanpet (M), Mahabubnagar District, Telangana',
        date: '10/03/2017',
        id: "Jajapur"
      },
      {
        imageUrl: this.path + 'garakurthi.jpg',
        projectTitle: 'Project 3: Mandal Parishad Primary School Garakurthi (V),kondapur (M), Sangareddy District, Telangana',
        date: '05/08/2017',
        id: "Garakurthi"
      },
      {
        imageUrl: this.path + 'malkeezguda.jpg',
        projectTitle: 'Project 4 : Mandal Parishad Primary School Malkeezguda (V),Yacharam (M), Rangareddy District, Telangana',
        date: '28/10/2017',
        id: "Malkeezguda"
      },
      {
        imageUrl: this.path + 'cherlapalem.jpg',
        projectTitle: 'Project 5 : Zilla Parishad High School Cherlapalem(V),Thorrur (M), Mahabubabad District, Telangana',
        date: '18/11/2017',
        id: "Cherlapalem"
      },
      {
        imageUrl: this.path + 'vutkur.jpg',
        projectTitle: 'Project 6: Upper Primary School Vutkur,Nidamanoor Mandal, Nalgonda District, Telangana',
        date: '06/01/2019',
        id: "Vutkur"
      },
      {
        imageUrl: this.path + 'ananthasagar.jpg',
        projectTitle: 'Project 7: Mandal Parishad Upper Primary School Anathasagar, Medak District, Telangana',
        date: '26/01/2018',
        id: "Anathasagar"
      },
      {
        imageUrl: this.path + 'burgumalla.jpg',
        projectTitle: 'Project 8: Upper Primary School Burgumalla, Parvathagiri, Warangal Rural District, Telangana',
        date: '11/03/2018',
        id: "Parvathagiri"
      },
      {
        imageUrl: this.path + 'marikal.jpg',
        projectTitle: 'Project 9: Zilla Parishad High School Marikal, Mahaboobnagar District, Telangana',
        date: '05/07/2018',
        id: "Marikal"
      },
      {
        imageUrl: this.path + 'dhanwada.jpg',
        projectTitle: 'Project 10: Zilla Parishad High School Dhanwada, Mahaboobnagar District, Telangana',
        date: '18/08/2019',
        id: "Dhanwada"
      },
      {
        imageUrl: this.path + 'thorrur.jpg',
        projectTitle: 'Project 11: Zilla Parishad High School Thorrur, Mahabubabad District, Telangana',
        date: '05/07/2019',
        id: "Thorrur"
      },
      {
        imageUrl: this.path + 'kanteyapalem.jpg',
        projectTitle: 'Project 12: Zilla Parishad High School Kanteyapalem, Mahabubabad District, Telangana',
        date: '20/07/2018',
        id: "Kanteyapalem"
      },
      {
        imageUrl: this.path + 'haripirala.jpg',
        projectTitle: 'Project 13: Zilla Parishad High School Haripirala, Mahabubabad District, Telangana',
        date: '20/07/2018',
        id: "Haripirala"
      },
      {
        imageUrl: this.path + 'lingampally.jpg',
        projectTitle: 'Project 14: Mandal parishad primary School Lingampally Thanda, Medak District, Telangana',
        date: '28/07/2019',
        id: "Lingampally"
      },
      {
        imageUrl: this.path + 'kondur.jpg',
        projectTitle: 'Project 15: Mandal Parishad Primary School Kondur, Rayaparti Mandal, Warangal Rural District, Telangana',
        date: '02/02/2019',
        id: "Kondur"
      },
      {
        imageUrl: this.path + 'badvel.jpg',
        projectTitle: 'Project 16: Zilla Parishad High School Badvel, Kadapa District, Andra Pradesh',
        date: '02/08/2019',
        id: "Badvel"
      },
      {
        imageUrl: this.path + 'hukrana.jpg',
        projectTitle: 'Project 17: Mandal Parishad Primary School Hukrana, Sangareddy, Telangana',
        date: '18/08/2019',
        id: "Hukrana"
      },
      {
        imageUrl: this.path + 'sonala.jpg',
        projectTitle: 'Project 18: Mandal Parishad Primary School Sonala, Adilabad District, Telangana',
        date: '03/09/2019',
        id: "Sonala"
      },
      {
        imageUrl: this.path + 'katrapally.jpg',
        projectTitle: 'Project 19: Zilla Parishad High School Katripally, Warangal Rural District, Telangana',
        date: '16/08/2019',
        id: "Katrapally"
      },
      {
        imageUrl: this.path + 'burgupally.jpg',
        projectTitle: 'Project 20: Mandal Parishad Primary School Burgupally, Jadcherla, Mahabubnagar District, Telangana',
        date: '19/12/2019',
        id: "Burgupally"
      },
      {
        imageUrl: this.path + 'medipally.JPG',
        projectTitle: 'Project 21: Zilla Parishad High School Medipally, Rangareddy District, Telangana',
        date: '14/12/2019',
        id: "Medipally"
      },
      {
        imageUrl: this.path + 'chinthaguda.jpg',
        projectTitle: 'Project 22: Zilla Parishad High School Chinthaguda, Manchieral, Telangana',
        date: '22/12/2019',
        id: "Chinthaguda"
      }, {
        imageUrl: this.path + 'peddavangara.jpg',
        projectTitle: 'Project 23: Zilla Parishad High School Peddavangara, Mahabubabad, Telangana',
        date: '14/03/2020',
        id: "Peddavangara"
      }
      ,
      {
        imageUrl: this.path + 'Shaipet.jpg',
        projectTitle: 'Project 24: ZPHS Shaipet, Dharmasagar Mandal, Hanumakonda District, Telangana',
        date: '05/03/2022',
        id: "Shaipet"
      }
      , {
        imageUrl: this.path + 'Venkatrampuram.jpg',
        projectTitle: 'Project 25: ZPHS Venkatrampuram Mahabubabad District, Telangana',
        date: '10/04/2022',
        id: "Venkatrampuram"
      }
      , {
        imageUrl: this.path + 'Motlathimapuram.jpg',
        projectTitle: 'Project 28: Motlathimapuram Village, Bayyaram Mandal, Mahabubabad District, Telangana',
        date: '13/11/2022',
        id: "MotlathimapuramBayyaram"
      }
      , {
        imageUrl: this.path + 'Gopalagiri.jpg',
        projectTitle: 'Project 27: MPPS Gopalagiri, Mahabubabad District, Telangana',
        date: '27/11/2022',
        id: "Gopalagiri"
      }
      , {
        imageUrl: this.path + 'Padmashalipuram.jpg',
        projectTitle: 'Project 28: ZPHS Padmashalipuram, Rajendranagar Mandal, Hyderabad, Telangana',
        date: '08/04/2023',
        id: "Padmashalipuram"
      }
    ]
  }

  public projectDetails(selectedPro: string) {
    localStorage.setItem("selectedProject", selectedPro);
    this.router.navigate(['/projects']);
  }
}
