import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printmedia',
  templateUrl: './printmedia.component.html',
  styleUrls: ['./printmedia.component.css']
})
export class PrintmediaComponent implements OnInit {

  public englishpath : string = 'assets/images/MediaCenter/Press/English/';
  public telugupath : string = 'assets/images/MediaCenter/Press/Telugu/';
  printMedia: Array<any> = [];
  constructor() { }

  ngOnInit() {
    this.allPrintMedia();
  }
  public allPrintMedia(){

    for(let i=1;i<=6;i++){
      this.printMedia.push({imageUrl: this.englishpath+ i +'.jpg'});
    }
    for(let i=1;i<=41;i++){
      this.printMedia.push({imageUrl: this.telugupath+ i +'.jpg'});
    }

    console.log(this.printMedia);
  }

}
