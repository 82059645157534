import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { AllprojectsComponent } from './allprojects/allprojects.component';
import { DonateusComponent } from './donateus/donateus.component';
import { JoinusComponent } from './joinus/joinus.component';
import { PrintmediaComponent } from './printmedia/printmedia.component';
import { NewslettersComponent } from './newsletters/newsletters.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CoreteamComponent } from './coreteam/coreteam.component';
import { PartnersComponent } from './partners/partners.component';
import { FounderstoryComponent } from './founderstory/founderstory.component';


import { HttpClientModule } from '@angular/common/http';
import { PaymentscreenComponent } from './paymentscreen/paymentscreen.component'
import { FormsModule } from '@angular/forms';
import {enableProdMode} from '@angular/core';

enableProdMode();
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectsComponent,
    AllprojectsComponent,
    DonateusComponent,
    JoinusComponent,
    PrintmediaComponent,
    NewslettersComponent,
    AboutusComponent,
    CoreteamComponent,
    PartnersComponent,
    FounderstoryComponent,
    PaymentscreenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, HttpClientModule ,FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
