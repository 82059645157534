import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected httpClient: HttpClient;
  protected apiName: string ='';

  protected Execute(methodName: string, requestBody?: any, requestType:string='get'): Observable<any> {
    if (!requestBody) {
      requestBody = null;
    }
    debugger;
     let header = this.getHeaders();
     console.log(requestBody);
     console.log(header);
     //header = new HttpHeaders({'Content-Type': 'application/json'});
   
     
    let url: string = this.getEndpointUrl(methodName, requestBody);
    switch(requestType)
    {
      case 'post':
      return this.httpClient.post(url, requestBody, { headers:header , responseType: 'text'}).pipe(
        map((response: string) => response),
        catchError((error) => this.handleError(error))
      );
      case 'get':
      return this.httpClient.get(url ,{headers:header , responseType: 'text'}).pipe(
        map((response: any) => response),
        catchError((error) => this.handleError(error))
      );
      case 'put':
        return this.httpClient.put(url,  requestBody, { headers:header }).pipe(
          map((response: string) => response),
          catchError((error) => this.handleError(error))
        );
      case 'delete':
        return this.httpClient.delete(url, { headers:header }).pipe(
          map((response: string) => response),
          catchError((error) => this.handleError(error))
        );
    }
  }

  private handleError(response: HttpErrorResponse): Observable<never> {
    debugger;
    return throwError(response);
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders({ 
      'Content-Type': 'application/json', 'amount': '1' });
   // headers.append('paymentamount', amount);
    return headers;
  }

  private getEndpointUrl(methodName: string, body:any): string {
    console.log("http://localhost:4000/"+methodName)
    return "http://localhost:8000/"+methodName; 
  }

  private isValidJSON(obj: any): boolean {
    try {
      JSON.parse(obj);
    } catch (e) {
      return false;
    }
    return true;
  }
}
