import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrls: ['./newsletters.component.css']
})
export class NewslettersComponent implements OnInit {
  public newsLettersPath : string = 'assets/images/NewsLetters/';
  public newsLetters2020:Array<any> = [];
  public newsLetters2019:Array<any> = [];
  public newsLetters2018:Array<any> = [];
  public newsLetters2017:Array<any> = [];
  public newsLetters2016:Array<any> = [];
 
  ngOnInit() {
    this.getNewsLetters();
  }

  public getNewsLetters(){
    let imgPath = 'assets/images/NewsLetters/';
    let jpg = '.jpg';
    let png = '.png';
    let gif = '.gif';
    for(let i=1;i<=5;i++){
      this.newsLetters2020.push({imageUrl: this.newsLettersPath+"2020/"+ i +'.png'});
    }
    for(let i=1;i<=5;i++){
      this.newsLetters2019.push({imageUrl: this.newsLettersPath+"2019/"+ i +'.jpg'});
    }
    for(let i=1;i<=5;i++){
      this.newsLetters2018.push({imageUrl: this.newsLettersPath+"2018/"+ i +'.jpg'});
    }
    for(let i=1;i<=11;i++){
      this.newsLetters2017.push({imageUrl: this.newsLettersPath+"2017/"+ i +'.jpg'});
    }
    for(let i=1;i<=3;i++){
      this.newsLetters2016.push({imageUrl: this.newsLettersPath+"2016/"+ i +'.jpg'});
    }
    
  }

}
