import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  public allNGOPartners:Array<any> = [];
  public path: string = 'assets/images/Team/NGOPartners/';
  constructor() { }

  ngOnInit() {
    this.getNGOPartners();
  }

  public getNGOPartners(){
    for(let i=1;i<=11;i++){
      this.allNGOPartners.push(
        {imageUrl: this.path+ i +'.jpg'}
      );
    }
  }

}
