import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from '../payment.service';

interface joinusdata{
  fullname?:string,
  email?:string,
  phone?:string,
  message?:string
}

@Component({
  selector: 'app-joinus',
  templateUrl: './joinus.component.html',
  styleUrls: ['./joinus.component.css']
})
export class JoinusComponent implements OnInit {

  public newMember : {}={fullname:'', email:'', phone:'',message:''};
  constructor(private paymentservice: PaymentService, private router: Router) { }

  ngOnInit() {
  }

  public sendemail(){
    console.log(this.newMember);
    this.paymentservice.joinus(this.newMember).subscribe((result)=>this.showmessage(result),
   (error)=>console.log("Something went wrong!"));
  }

  public showmessage(result){

    if(result=="OK"){
      alert("Thanks for joining us!");
      //this.toaster.success("Thanks for joining us", "We recieved your information !");
    }
    else{
      alert("Something went wrong. Please try again later!")
      //this.toaster.error("Failure", "Something went wrong. Please try again !");
    }
  }

  public erromessage(){
    alert("Something went wrong!")
  }

}
