import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  galleryData: Array<any>;
  public title: string = '';
  public memory: string = '';
  public description: string = '';
  constructor() { }

  ngOnInit() {
    this.getLingamPallyProjectImages()
    this.showProjectGallery();
  }

  public showProjectGallery() {
    let proj = localStorage.getItem("selectedProject");
    switch (proj) {
      case "Chinnanagaram": this.getChinnanagaramProjectImages();
        break;
      case "Jajapur": this.getJajapurProjectImages();
        break;
      case "Garakurthi": this.getGarakurthiProjectImages();
        break;
      case "Malkeezguda": this.getMalkeezgudaProjectImages();
        break;
      case "Cherlapalem": this.getCherlapalemProjectImages();
        break;
      case "Vutkur": this.getVutkurProjectImages();
        break;
      case "Anathasagar": this.getAnathasagarProjectImages();
        break;
      case "Parvathagiri": this.getParvathagiriProjectImages();
        break;
      case "Marikal": this.getMarikalProjectImages();
        break;
      case "Dhanwada": this.getDhanwadaProjectImages();
        break;
      case "Kanteyapalem": this.getKanteyapalemProjectImages();
        break;
      case "Haripirala": this.getHaripiralaProjectImages();
        break;
      // case "Lingampally":this.getLingampallyProjectImages();
      case "Thorrur": this.getThorrurProjectImages();
        break;
      case "LingamPally": this.getLingamPallyProjectImages();
        break;
      case "Kondur": this.getKondurProjectImages();
        break;
      case "Hukrana": this.getHukranaProjectImages();
        break;
      case "Badvel": this.getBadvelProjectImages();
        break;
      case "Sonala": this.getSonalaProjectImages();
        break;
      case "Katrapally": this.getKatrapallyProjectImages();
        break;
      case "Burgupally": this.getBurgupallyProjectImages();
        break;
      case "Medipally": this.getMedipallyProjectImages();
        break;
      case "Chinthaguda": this.getChinthagudaProjectImages();
        break;
      case "Peddavangara": this.getPeddavangaraProjectImages();
        break;
      case "Shaipet": this.getShaipetProjectImages();
        break;
      case "Venkatrampuram": this.getVenkatrampuramProjectImages();
        break;
      case "MotlathimapuramBayyaram": this.getMotlathimapuramBayyaramProjectImages();
        break;
      case "Gopalagiri": this.getGopalagiriProjectDetails();
        break;
      case "Padmashalipuram": this.getPadmashalipuramProjectDetails();
        break;


      //default:this.getChinnanagaramProjectImages();
    }
  }
  public getHukranaProjectImages() {
    let imgPath = "assets/images/Sonala/";
    this.title = `Project 17: MPUPS Hukrana, Manoor Mandal, Sangareddy District`;
    this.memory = "In the Memory of Sarvepalli Radhakrishnan Garu";
    this.description = `Feb 23, 2019<br>
      Hyderabad, India<br><br>
      MPUPS Hukrana is approximately 210kms from Hyderabad, the school strength is 90 students upto 7th standard.
      <br><br>
      We have worked in the school with the support of our CSR partner Aspire systems Private Limited
and with our NGO partner abhaya Foundation.
<br><br>
Provide Digital Classroom to the school and inaugurated in the presence of Praney Sundeep IAS
Garu Project Director for Telangana Residential Schools, Mandal Education officer Manoor Garu,
abhaya Foundation Secretary Sivakrishna Garu, school HM Akbar Garu &amp; staff, villager’s, children
and 100 Smiles Founder Manoj Chittimalla, General Secretary Madhukar Reddy, Joint secretary
Ram Prasad Reddy.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }

      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }
  public getSonalaProjectImages() {
    let imgPath = "assets/images/Sonala/";
    this.title = `Project 18: Mandal Parishad Primary School Sonala, Adilabad District, Telangana`;
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `Feb 08, 2019<br>
      Hyderabad, India<br><br>
      Mandal Parishad Primary School is located in Adilabad District, Telangana with a strength of 1100 strength, We have worked in the school with the support of our CSR partner Aspire systems Private Limited and with our NGO partner abhaya Foundation &amp; PAC India.
      <br><br>
      We have provided Digital Classroom and inaugurated in the presence of Shri Muktheshwara Rao Garu TTD Director &amp; former collector of Nalgonda, Telangana, 100 Smiles Adviser &amp; PAC NGO founder
Srikanth Reddy Maramreddy, 100 smiles Founder Manoj Chittimalla &amp; General secretary Madhukar
Reddy, school staff , Old students, parents &amp; Children.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }

      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "17.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]

  }
  public getKatrapallyProjectImages() {
    let imgPath = "assets/images/Katrapally/";
    this.title = `Project 19: Zilla Parishad High School Katrapally, Warangal Rural District, Telangana`;
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `August 16th, 2019<br>
      Hyderabad, India<br><br>
      This school is located in Katrapally viallge Raiparthy Mandal, Warangal rural district of Telangana.
      <br><br>
      We Provided computer & Printer to develop the computer knowledge skill in the students , and to maintain healthy physical fitness in students we provided Sports material and also Distributed notebooks for there regular use.
      <br><br>
      Event conduct in the presence of School HM Venkateshwarlu Garu, Sri Vani Foundation Chairman Nagavani Garu, Rayaparthi Mandal MPP Amini Reddy Garu ZPTC Rangi Kumar Swamy Garu, School staff, Students & Villagers.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }
  public getBurgupallyProjectImages() {
    let imgPath = "assets/images/Burgupally/";
    this.title = `Project 20: Mandal Parishad Primary School Burgupally, Jadcherla, Mahabubnagar District, Telangana`;
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `December 19th, 2019<br>
      Hyderabad, India<br><br>
      This school is located in Burgupally village, Jadcherala Mandal, Mahaboobnagar District.
      <br><br>
      With the support of our CSR partner Genesys Telcom Labs Private Ltd, Provided Digital Classroom, E-Material & Dual Desk benches in MPPS Burgupally. This will really help the students to improve their knowledge in all aspects.
      <br><br>
      Digital Classroom Inaugurated by Genesys Site Director Sanjay Krishnaswamy in the presence of Genesys employees, Students, MEO Manjula Devi, AMO Hemachandra, School HM, staff, village representatives & parents.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }
  public getMedipallyProjectImages() {
    let imgPath = "assets/images/Medipally/";
    this.title = `Project 21: Zilla Parishad High School Medipally`;
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `December 14th, 2019<br>
      Hyderabad, India<br><br>
      This school located in Medipally Village, Yacharam Mandal, Rangareddy District.
      <br><br>
      Happiest moment is When your services benefit 100s of young minds.Provided Computer lab in ZPHS Medipally in collaboration with abhaya Foundation and DXC Foundation which will benefit 300 students. 10th class students Roja and Sunitha inaugurated the computer lab.
      <br><br>
      Abhaya Foundation Founder Bala chandra Garu joined us in this inaugural ceremony, School staff, students, village representatives participated in the inaugural ceremony.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.JPG",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.JPG",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.JPG",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.JPG",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.JPG",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.JPG",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.JPG",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.JPG",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.JPG",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }
  public getChinthagudaProjectImages() {
    let imgPath = "assets/images/Chinthaguda/";
    this.title = `Project 22: Zilla Parishad High School, Chinthaguda`;
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `December 22nd, 2019<br>
      Hyderabad, India<br><br>
      This school located in Chinthaguda village, Janaram Mandal, Manchieral District      
      <br><br>
      Collaboration project with abhaya Foundation & DXC Foundation.Provided & Inaugurated Digital classroom & E material to ZPHS Chinthaguda, Manchieral District. Spoke with Villagers, Mandal Officials about school development & Interacted with SSC bath students and planning to guide them in achieving their goals.
      <br><br>
      School staff, students, village representatives joined us in the inaugural ceremony, later discussed with villagers about the school development.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }
  public getPeddavangaraProjectImages() {
    let imgPath = "assets/images/Peddavangara/";
    this.title = 'Project 23: Zilla Parishad High School, Peddavangara.'
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `14th March 2020<br>
      Hyderabad, India<br><br>
      This school is located in Peddavangara village & Mandal, Mahabubabad District.
      <br><br>
      Provided Computer Lab in ZP High School Peddavangara which will be benefiting 300+ students. In Collaboration with abhaya Foundation & CSR Partner DXC Foundation.
      <br><br>
      Our Founder Manoj Chittimalla joined in the Computer Lab inaugural ceremony and graced the event, addressed the gathering. Later discussed with villagers about the development of school.
      School staff, students, village representatives joined us in the inaugural ceremony
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }

  public getParvathagiriProjectImages() {
    let imgPath = 'assets/images/Parvathagiri/';
    this.title = `Project 11: Zilla Parishad High School Parvathagiri, Mahabubabad District.`;
    this.memory = "In the memory of Shri Bhagath Singh Garu";
    this.description = `July 20, 2018 <br>
    Hyderabad, India<br><br>
    This school is located 207km far away from Hyderabad which has a strength of 800. Our third project in collaboration with Global Hunt foundation. 
    <br><br>
    100 Smiles visited the school and researched about their needs and approached Global Hunt foundation advisor Dr. Sharma Garu. We received 100% financial support from Global Hunt team for this school.
    <br><br>
    On July 20th 2018 we visited the school and in the presence Dr Jayapraksah Narayan IAS, Rajarathnam Garu DSP Thorrur, Sreekanth Reddy Maram reddy Garu, PAC India Founder, Bandaru Ram mohan Rao Garu,Loksatta Andhra and Telangana President ,Dasari Mallaiah Garu, Freedom Fighter, Babu Garu , MPDO, Thorrur, Karne Somaiah Garu, MPP Thorrur, Mahankali buchaiah Garu, MEO Thorrur, Narendar reddy Garu, AMC Thorrur, Rajesh Naik Garu, Sarpanch Thorrur, 100 smiles Advisors Panja Kalpana and Chittimalla Mahesh Garu, school staff, students and villagers, we have provided them with computer lab, science lab, Book library, sports material.`
    this.galleryData = [

      {
        imageUrl: imgPath + '01.jpg',
        projectTitle: 'Zilla Parishad High School Thorrur',
        date: '18/08/2019'
      },
      {
        imageUrl: imgPath + '02.JPG',
        projectTitle: 'Children in science lab'
      },
      {
        imageUrl: imgPath + '03.jpg',
        projectTitle: 'Children doing experiments in lab'
      },
      {
        imageUrl: imgPath + '04.JPG',
        projectTitle: 'Children reading books in provided book library'
      },
      {
        imageUrl: imgPath + '05.JPG',
        projectTitle: 'Children in Library'
      },
      {
        imageUrl: imgPath + '06.JPG',
        projectTitle: 'Children in Digital Library'
      },
      {
        imageUrl: imgPath + '07.JPG',
        projectTitle: 'Childre standing in queue to welcome Dr Jayaprakash Narayan IAS'
      },
      {
        imageUrl: imgPath + '08.JPG',
        projectTitle: 'Dr JP Interacting with children',
      },
      {
        imageUrl: imgPath + '09.JPG',
        projectTitle: 'Dr JP inagurating Digital Library',
      },
      {
        imageUrl: imgPath + '10.jpg',
        projectTitle: 'Book library',
      },
      {
        imageUrl: imgPath + '11.JPG',
        projectTitle: 'In Science Lab',
      },
      {
        imageUrl: imgPath + '12.jpg',
        projectTitle: 'Founder Manoj Chittimalla addressing the meeting',
      },
      {
        imageUrl: imgPath + '13.JPG',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '14.JPG',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '15.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '16.JPG',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '17.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '19.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '20.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '21.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '22.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '23.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      }
    ]
  }

  public getVutkurProjectImages() {
    let imgPath = 'assets/images/Vutkur/';
    this.title = `Project 6: Upper Primary School Vutkur,Nidamanoor Mandal, Nalgonda District.`;
    this.memory = "In the memory of Late Shri. Subhas Chandra Bose Garu";
    this.description = `July 20, 2018 <br>
    Hyderabad, India<br><br>
    Upper Primary School Vutkur which is 160kms far from Hyderabad and rural part of Nalgonda a strength of 187 children.
    <br><br>
    Children intake the fluorinated water directly which leads to polio and other disabilities.
    Since there is a water scarcity we are providing RO water Purifier for the children.
    And we have also made Library setup for the School provided them with Library racks & books and established a library in the school.
    <br><br>
    We also conducted meeting with Village sarpanch and panchayat members and involving in the school development, we are keenly monitoring the school and planning to provide the digital classroom in the academic year 2018-19.
    `

    this.galleryData = [

      {
        imageUrl: imgPath + '01.jpg',
        projectTitle: '',
        date: '18/08/2019'
      },
      {
        imageUrl: imgPath + '02.jpg',
        projectTitle: 'Children in science lab'
      },
      {
        imageUrl: imgPath + '03.jpg',
        projectTitle: 'Children doing experiments in lab'
      },
      {
        imageUrl: imgPath + '04.jpg',
        projectTitle: 'Children reading books in provided book library'
      },
      {
        imageUrl: imgPath + '05.jpg',
        projectTitle: 'Children in Library'
      },
      {
        imageUrl: imgPath + '06.jpg',
        projectTitle: 'Children in Digital Library'
      },
      {
        imageUrl: imgPath + '07.jpg',
        projectTitle: 'Childre standing in queue to welcome Dr Jayaprakash Narayan IAS'
      },
      {
        imageUrl: imgPath + '08.jpg',
        projectTitle: 'Dr JP Interacting with children',
      },
      {
        imageUrl: imgPath + '11.jpg',
        projectTitle: 'Dr JP inagurating Digital Library',
      },
      {
        imageUrl: imgPath + '12.jpg',
        projectTitle: 'Book library',
      },
      {
        imageUrl: imgPath + '13.jpg',
        projectTitle: 'In Science Lab',
      },
      {
        imageUrl: imgPath + '14.jpg',
        projectTitle: 'Founder Manoj Chittimalla addressing the meeting',
      },
      {
        imageUrl: imgPath + '15.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '16.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '17.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      },
      {
        imageUrl: imgPath + '18.jpg',
        projectTitle: 'Dr JP Addressing the meeting',
      }
    ]

  }

  public getDhanwadaProjectImages() {
    let imgPath = 'assets/images/Dhanwada/';
    this.title = `Project 10: Zilla Parishad High School Dhanwada, Mahaboobnagar District`;
    this.memory = "In the memory of Smt. Indira Gandhi Garu";
    this.description = `July 20, 2018 <br>
    Hyderabad, India<br><br>
    This school is located 153km far away from Hyderabad which has a strength of 622. Our second project in collaboration with Global Hunt foundation. 100 Smiles visited the school and researched about their needs and approached Global Hunt foundation advisor Dr. Sharma Garu.    <br><br>
    <br><br>
    We received 100% financial support from Global Hunt team for this school .On July 5th 2018 we visited the school and in the presence of Dr. Sharma Global Hunt foundation Advisor, Sreekanth Reddy PAC founder,Sarpanch Laxmi Devi, MPTC Sowbhagya Laxmi, school incharge Balalingaiah garu, children and villagers we have provided them with computer lab, science lab, Book library, sports material.
    `
    this.galleryData = [

      {
        imageUrl: imgPath + '01.jpg',
        projectTitle: 'Zilla Parishad High School Thorrur',
        date: '18/08/2019'
      },
      {
        imageUrl: imgPath + '02.jpg',
        projectTitle: 'Children in science lab'
      },
      {
        imageUrl: imgPath + '03.jpg',
        projectTitle: 'Children doing experiments in lab'
      },
      {
        imageUrl: imgPath + '04.jpg',
        projectTitle: 'Children reading books in provided book library'
      },
      {
        imageUrl: imgPath + '05.jpg',
        projectTitle: 'Children in Library'
      },
      {
        imageUrl: imgPath + '06.jpg',
        projectTitle: 'Children in Digital Library'
      },
      {
        imageUrl: imgPath + '07.jpg',
        projectTitle: 'Childre standing in queue to welcome Dr Jayaprakash Narayan IAS'
      },

    ]
  }

  public getThorrurProjectImages() {

    let imgPath = 'assets/images/Thorrur/';
    this.title = `Project 11: Zilla Parishad High School Thorrur, Mahabubabad District.`;
    this.memory = "In the memory of Shri Bhagath Singh Garu";
    this.description = `July 20, 2018 <br>
    Hyderabad, India<br><br>
    This school is located 207km far away from Hyderabad which has a strength of 800. Our third project in collaboration with Global Hunt foundation. 
    <br><br>
    100 Smiles visited the school and researched about their needs and approached Global Hunt foundation advisor Dr. Sharma Garu. We received 100% financial support from Global Hunt team for this school.
    <br><br>
    On July 20th 2018 we visited the school and in the presence Dr Jayapraksah Narayan IAS, Rajarathnam Garu DSP Thorrur, Sreekanth Reddy Maram reddy Garu, PAC India Founder, Bandaru Ram mohan Rao Garu,Loksatta Andhra and Telangana President ,Dasari Mallaiah Garu, Freedom Fighter, Babu Garu , MPDO, Thorrur, Karne Somaiah Garu, MPP Thorrur, Mahankali buchaiah Garu, MEO Thorrur, Narendar reddy Garu, AMC Thorrur, Rajesh Naik Garu, Sarpanch Thorrur, 100 smiles Advisors Panja Kalpana and Chittimalla Mahesh Garu, school staff, students and villagers, we have provided them with computer lab, science lab, Book library, sports material.`
    this.galleryData = [
      {
        imageUrl: imgPath + '01.jpg',
        projectTitle: 'Zilla Parishad High School Thorrur',
        date: '18/08/2019'
      },
      {
        imageUrl: imgPath + '02.jpg',
        projectTitle: 'Children in science lab'
      },
      {
        imageUrl: imgPath + '03.jpg',
        projectTitle: 'Children doing experiments in lab'
      },
      {
        imageUrl: imgPath + '04.jpg',
        projectTitle: 'Children reading books in provided book library'
      },
      {
        imageUrl: imgPath + '05.jpg',
        projectTitle: 'Children in Library'
      },
      {
        imageUrl: imgPath + '06.jpg',
        projectTitle: 'Children in Digital Library'
      },
      {
        imageUrl: imgPath + '07.jpg',
        projectTitle: 'Childre standing in queue to welcome Dr Jayaprakash Narayan IAS'
      },
      {
        imageUrl: imgPath + '08.jpg',
        projectTitle: 'Dr JP Interacting with children',
      },
      {
        imageUrl: imgPath + '09.jpg',
        projectTitle: 'Dr JP inagurating Digital Library',
      },
      {
        imageUrl: imgPath + '10.jpg',
        projectTitle: 'Book library',
      },
      {
        imageUrl: imgPath + '11.jpg',
        projectTitle: 'Dr JP Interacting with children',
      },
      {
        imageUrl: imgPath + '12.jpg',
        projectTitle: 'Dr JP inagurating Digital Library',
      },
      {
        imageUrl: imgPath + '13.jpg',
        projectTitle: 'Book library',
      }
    ]
  }
  public getChinnanagaramProjectImages() {

    let imgPath = "assets/images/Chinnanagaram/";
    this.title = `Project 1: Upper Primary School Chinnanagaram (V),Nellikudur (M),
    Mahabubabad District, Telangana.`
    this.memory = "In the Memory of Shri. Dr A.P.J Abdul Kalam Garu";
    this.description = `<p>August 15, 2016<br>
    Hyderabad, India<br><br>
    We started our first project on our Independence day, Upper Primary School at
    Chinnanagaram village, Nelikuduru mandal is 180 kms from Hyderabad.
    <br><br>
    Initially, We provided chairs to 130 students, besides taking up SWACHH campaign we
    also started plantation drive to improve the aesthetics of the school and we gave school
    walls, a revamp with paintings of national, state logos and birds.
    <br><br>
    Sports kits were also supplied to encourage physical activities among the students. In a
    bid to develop a sense of responsibility we formed student committees for Cleanliness,
    Health, Cultural and Mid-day meals implementation.
    Later in the month of november we provided virtual classroom (projector, computer,
    internet facility, web camera). The virtual classroom concept which allows subject
    experts from anywhere in the country speak directly to the children through internet
    video calling facility. This will enable wide range of subjects such as academics, sports,
    culture, tradition etc.
    Previously government sanctioned to school with another classroom but due to some
    internal village issues, the work was stopped. 100smiles team spoke with mandal and
    district education officers and sanctioned the amount of 2.5 lakhs from the government to
    complete the construction of classrooms.<p>`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "UPS School Chinnanagaram",
        date: "15/08/2016"
      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "Tree Plantation"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "Speech by a Kid"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Cultural Art's Performance"

      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "Meeting Session"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Meeting Session"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Founder Thoughts"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Inside Virtual Class Room",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Virtual ClassRoom Launch",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Before and After Renovation",
      }
    ]
  }

  public getJajapurProjectImages() {

    let imgPath = "assets/images/Jajapur/";
    this.title = `Project 2 : Mandal Parishad Primary School Jajapur (V),Narayanpet (M),
    Mahabubnagar District, Telangana.`;
    this.memory = "In the Memory of Shri.Jawaharlal Nehru Garu";
    this.description = `July 7, 2017<br>
    Hyderabad, India<br><br>
    
    With lots of hopes and responsibilities 100 SMILES has started its second project i.e.,
    Mandal Parishad primary School E/M &amp; T/M. Jajapur village, Narayanpet Mandal,
    Mahaboobnagar district which consist of nearly 201 students.
    <br><br>
    At the time of inspection, we found that the children were not at all having access to
    drinking water, there was a bore pump in their compound which was used for drinking
    water, it was damaged because of high demand in summer. We managed to give water
    pipeline connection and provided them with RO purified water. And, the school started
    using the rejected water disposal from RO filter to water plants.
    <br><br>
    In the month of August, we Inaugurated Digital classroom in the presence of students,
    school staff, Mandal Educational Officer, Sarpanch and Press. And, conducted
    motivational session with students.
    Still we found that the school doesnâ€™t have water connection to washrooms. We
    discussed the same with Sarpanch to sort out the issue and extended our supporting in
    providing them hygienic washrooms.`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "MPPS School Jajapur",
      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "Children in the school"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "Inaguration of Water Purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Inaguration of Digital Classroom"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "With the Children in Digital Classroom"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Meeting Session, Speech by School Staff"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Meeting Session"
      },

      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Founder Manoj Sharing his Thoughts",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Water Purifier Inaguration",
      },
    ]
  }

  public getGarakurthiProjectImages() {

    let imgPath = "assets/images/Garakurthi/";
    this.title = `Project 3 : Mandal Parishad Primary School Garakurthi (V),kondapur (M),
    Sangareddy District, Telangana.`;
    this.memory = "In the Memory of Shri. Lal Bahadur Shasthri Garu";
    this.description = `August 5, 2017<br>
    Hyderabad, India<br><br>
    After close inspection in the village, we found that the drinking water intake has high
    amounts of fluorine which was adversely affecting school children and staff health. They
    were not having access to purified water. We handed over them with RO water purifier
    fetching 10 ltrs of purified water per hour to the school in the first week of August
    2017.<br><br>
    We provided school kits to the children including notebooks &amp; stationery. We met the
    Mandal Educational Officer and Village Panchayat members about the development of
    school.<br><br>
    Friendship Day Celebrations and Career Development and Planning session was
    conducted, to help out the students on how to continue their education and what are the
    career opportunities.
    For the academic year(2018-2019), we have distributed them with recycled notebooks in
    association with VNRSF. We conducted an interactive session to educate them how
    important the education is and awareness about environment and to use recycled books
    and to use the natural resources efficiently.`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "MPPS School Garakurthi",
        date: "15/08/2016"
      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "Distribution of School Kits"
      },
      {

        imageUrl: imgPath + "03.jpg",
        projectTitle: "Inauguration of Water Purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "A Pic with Children &amp; School Staff"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "Inaguration of Water Purifier by MEO"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Notebooks"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Friendship Bands to the Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Children with Friendship Bands",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Meeting Session (MEO, Village Officials)",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Children Celebrating Friendship Day",
      },
    ]
  }

  public getMalkeezgudaProjectImages() {

    let imgPath = "assets/images/Malkeezguda/";
    this.title = `Project 4 : Mandal Parishad Primary School Malkeezguda (V),Yacharam (M),
    Rangareddy District, Telangana.`;
    this.memory = "In the Memory of Shri. Sardar Vallabhbhai Patel Garu";
    this.description = `October 28, 2017<br>
    Hyderabad, India<br><br>
    In our Inspection, the first thing that we found is very less school strength. The school
    was very well constructed but the occupancy was very low.
    <br><br>
    
    Conducted a session of motivation (BADI BATA) with the village representatives to
    make them know the importance of education and how education will have influence on
    them and their children. They formed groups to campaign regarding the importance of
    education and make them join the school.
    <br><br>
    Later we provided each student with school bags, Set up a small library with a rack and
    texbooks, sports kits for their physical development
    In our second phase we have visited the school and we have digitized the school,
    provided the digital classroom material like projector, computer, Internet connection.
    In the academic year 2018-19 we have visited the school and provided the children with
    recycled notebooks. Now the strength is 75children.`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Mandal Parishad Primary School Malkeezguda",
      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "Children with Provided Bags"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "Distribution of Bags"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Digital Class Room Inauguration"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "Distributed Rack, Books &amp; Sports Kits"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Children Listening to School HM Mr.Srinivas Speech"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "100 Smiles Founder Manoj Addresing the Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "100 Smiles Genral Secretary Madhukar Giving Speech",
      },

      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Mr.Ramesh Donating 5000/- Check to 100 Smiles",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "100 Smiles Treasurer Donating Bag to School Children",
      },
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Mr.Srikanth from Aspire Systems Donating Bag",
      },
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Manoj Interacting with Children in Digital Class Room",
      },
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "100 Smiles and Aspire Systems Team with the children in Digital ClassRoom",
      },
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "In Digital ClassRoom",
      },
    ]
  }

  public getCherlapalemProjectImages() {

    let imgPath = "assets/images/Cherlapalem/";
    this.title = `Project 5 : Zilla Parishad High School Cherlapalem(V),Thorrur (M),
    Mahabubabad District, Telangana.`;
    this.memory = "In the Memory of Shri P.V Narasimha Rao Garu";
    this.description = `November 18, 2017<br>
    Hyderabad, India<br><br>
    ZPHS Cherlaplem which is 180kms far from Hyderabad, Strength of the school is 183
    children. when we researched about school we found that children walk nearly 3-5km
    bare foot to come to school.
    <br><br>
    Hence to protect them, we distributed shoes to all the children and also apart from that we
    have provide virtual classroom to the school.
    <br><br>
    
    We have provided unlimited internet connection to the school and connected the digital
    classroom with internet connection and teaching the children with English and computer
    subjects.
    Recently we made children to listen to Prime minister Narendra Modi speech "Pariksha
    Par Charchaa"Live from Delhi through our virtual classroom setup.`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Zilla Parishad High School",
      },
      {
        imageUrl: imgPath + "02.JPG",
        projectTitle: "Welcoming Chief Guest DSP Rajaratnam"
      },
      {
        imageUrl: imgPath + "03.JPG",
        projectTitle: "DSP Rajaratnam Inaugurating Virtual ClassRoom"
      },
      {
        imageUrl: imgPath + "04.JPG",
        projectTitle: "DSP Launches Digital ClassRoom"
      },
      {
        imageUrl: imgPath + "05.JPG",
        projectTitle: "Former Treasurer Sravani Inaugurating"
      },
      {
        imageUrl: imgPath + "06.JPG",
        projectTitle: "School HM Addresing the Event"
      },
      {
        imageUrl: imgPath + "07.JPG",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "08.JPG",
        projectTitle: "Children Performing Cultural Dance",
      },
      {
        imageUrl: imgPath + "09.JPG",
        projectTitle: "Manoj Addresing the Children",
      },
      {
        imageUrl: imgPath + "10.JPG",
        projectTitle: "Helping Student to Tie his Shoe Lace",

      },
      {
        imageUrl: imgPath + "11.JPG",
        projectTitle: "100 Smiles Member Sudeer Distributing Shoes to Student",
      },
      {
        imageUrl: imgPath + "12.JPG",
        projectTitle: "Chief Guest DSP Rajaratnam Addresing the Students",
      },
      {
        imageUrl: imgPath + "13.JPG",
        projectTitle: "A Pic with DSP Rajaratnam",
      },
      {
        imageUrl: imgPath + "14.JPG",
        projectTitle: "Interacting with Media",
      },
    ]
  }

  public getNidamanoorProjectImages() {

    let imgPath = "assets/images/Nidamanoor/";
    this.title = `Project 6: Upper Primary School Vutkur,Nidamanoor Mandal, Nalgonda
    District.`;
    this.memory = "In the Memory of Late Shri. Subhas Chandra Bose Garu";
    this.description = `January 06, 2018<br>
    Hyderabad, India<br><br>
    Upper Primary School Vutkur which is 160kms far from Hyderabad and rural part of
    Nalgonda a strength of 187 children
    <br><br>
    Children intake the fluorinated water directly which leads to polio and other disabilities.
    Since there is a water scarcity we are providing RO water Purifier for the children.
    <br><br>
    And we have also made Library setup for the School provided them with Library racks &amp;
    books and established a library in the school.
    We also conducted meeting with Village sarpanch and panchayat members and involving
    in the school development, we are keenly monitoring the school and planning to provide
    the digital classroom in the academic year 2018-19.`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper primary School Vutkur",
      },

      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "with MEO and school Staff"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "Inauguration of water purifier by MEO"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "With children at water putrifer tap setup"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "Teaching class to the children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "with the school children"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "school children",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "with school staff, village sarpanch &amp; MPTC",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "founder Manoj sharing his experince to children",
      },
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Metting session",
      },
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "100 smiles member Prasana addressing the meeting",
      },
      {
        imageUrl: imgPath + "13.jpg",

        projectTitle: "Distributing the notebooks to the children",
      },
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "100 smiles member shiva distributing notebooks to the children",
      },
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "100smiles team with staff &amp; guests",
      },
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: "100smiles team with school children",
      },
      {
        imageUrl: imgPath + "17.jpg",
        projectTitle: "Having mid day meals with children",
      },
    ]
  }

  public getAnathasagarProjectImages() {

    let imgPath = "assets/images/Anathasagar/";
    this.title = `Project 7: Mandal Parishad Upper Primary School
    Anathasagar, Medak District.`;
    this.memory = "In the Memory of Shri. C.V Raman Garu";
    this.description = `January 26, 2018<br>
    Hyderabad, India<br><br>
    M.P.U.P School Anathasagar Medhak which is 84kms far from Hyderabad with strength
    of 206. After inspection, we provided them with RO water purifier which would fetch
    25ltrs/hour.
    <br><br>
    And for the physical fitness purpose we have provided sports material to the children and
    we discussed with school management and made a mandatory sports hour in the
    timetable for all the children.
    <br><br>
    We have also interacted with village youth organizations and we have understood that a
    lot of village youth are preparing for competitive exams and they lack of study materials,
    so in collaboration with People Against Corruption Organization we are going to setup a Library in the village.`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Mandal Parishad Upper Primary School Anathasagar",
      },

      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 Smiles team members at donated water purifer"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "School children"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "School children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "MPP Inagurating the Water Purifier"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Flag hosting on the occassion of Republic Day"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Founder Manoj Chittimalla speech in the meeting",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "100 smiles members distibuting the sports materials to the children",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "School children with sports materials",
      },
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Genral secretary Madhukar reddy distributing sport materials",
      },
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Manoj with children",
      },
      {
        imageUrl: imgPath + "13.jpg",

        projectTitle: "School children",
      },
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "Treasurer Shireesha Pochammla with children",
      },
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "PAC Founder Sreekanth Reddy Garu with children",
      },
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: "100 smiles team with children",
      },
      {
        imageUrl: imgPath + "17.jpg",
        projectTitle: "100 smiles team with school staff, guests &amp; children",
      },
    ]
  }

  public getBurgumallaProjectImages() {

    let imgPath = "assets/images/Burgumalla/";
    this.title = `Project 8: Upper Primary School Burgumalla, Parvathagiri, Warangal Rural
    District.`;
    this.memory = "In the Memory of Late Shri. Prof Jayashankar Garu";
    this.description = `March 11, 2018<br>
    Hyderabad, India<br><br>
    UPS Burgumalla which is 178kms far from Hyderabad, the school has a strength of 146
    children. We have found that this village is very far from the town and far away from
    basic amenities and children are lack of purified water and quality education.
    <br><br>
    We have provided the school with RO water Purifier made the children free from
    drinking un purified water and we also setup a Digital classroom in the school which
    consists of Projector , computer, projector screen and Digital class material.
    <br><br>
    And to encourage physical fitness in children we have provided sports materials to the
    children and made sports hour a mandatory in the time table.
    In the academic year 2018-19 we are planning to provide Notebooks for the children. `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "school children",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "100 smiles team , staff, guests in digital classroom",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "100 smiles member vijay speech in meeting",
      },
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "children standing in prayer meeting",
      },
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "school children",
      },
      {

        imageUrl: imgPath + "13.jpg",
        projectTitle: "2nd phase meeting with guets &amp; school staff",
      },
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "Meeting session",
      },
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "Felicitating our adviser Lakshmi Narasimha Ikkurthi",
      },
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: "Children listening to Dr Jayaprakash Narayan speech in digital classroom",
      },
      {
        imageUrl: imgPath + "17.jpg",
        projectTitle: "100 Smiles team members in provided digital classroom",
      },
      {
        imageUrl: imgPath + "18.jpg",
        projectTitle: "100 Smiles team members",
      },
      {
        imageUrl: imgPath + "19.jpg",
        projectTitle: "100 Smiles team members in provided digital classroom",
      },
    ]
  }

  public getMarikalProjectImages() {

    let imgPath = "assets/images/Marikal/";
    this.title = `Project 9: Zilla Parishad High School Marikal, Mahaboobnagar District`;
    this.memory = "In the Memory of Late Smt. Kalpana Chawla Garu";
    this.description = `March 11, 2018<br>
      Hyderabad, India<br><br>
      This school is located 144km far away from Hyderabad which has a strength of 714. Our first project in collaboration with Global Hunt foundation. 100 Smiles visited the school and researched about their needs and approached Global Hunt foundation advisor Dr. Sharma Garu.
      <br><br>
      We received 100% financial support from Global Hunt team for this school and next 4 schools. On July 5th 2018 we visited the school and in the presence of Dr. Sharma Global Hunt foundation Advisor, Sreekanth Reddy PAC founder,Sarpanch Laxmi Devi, MPTC Sowbhagya Laxmi, school incharge Balalingaiah garu, children and villagers we have provided them with computer lab, science lab, Book library, sports material.
       `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "school children",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "100 smiles team , staff, guests in digital classroom",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "100 smiles member vijay speech in meeting",
      },
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "school children",
      },
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "2nd phase meeting with guets &amp; school staff",
      },
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "Meeting session",
      },
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "Felicitating our adviser Lakshmi Narasimha Ikkurthi",
      }
    ]
  }

  public getKanteyapalemProjectImages() {

    let imgPath = "assets/images/Kanteyapalem/";
    this.title = `Project 12: Zilla Parishad High School Kanteyapalem, Mahabubabad District.`;
    this.memory = "In the Memory of Puchalapalli Sundarayya Garu";
    this.description = `March 11, 2018<br>
        Hyderabad, India<br><br>
        This school is located 220km far away from Hyderabad which has a strength of 420. Our fourth project in collaboration with Global Hunt foundation.
        <br><br>
        100 Smiles visited the school and researched about their needs and approached Global Hunt foundation advisor Dr. Sharma Garu. We received 100% financial support from Global Hunt team for this school. On July 20th 2018 we visited the school and in the presence Dr Jayapraksah Narayan IAS, Rajarathnam Garu DSP Thorrur, Sreekanth Reddy Maram reddy Garu, PAC India Founder, Bandaru Ram mohan Rao Garu,Loksatta Andhra &amp; Telangana President ,Dasari Mallaiah Garu, Freedom Fighter, Babu Garu , MPDO, Thorrur, Karne Somaiah Garu, MPP Thorrur, Mahankali buchaiah Garu, MEO Thorrur, Narendar reddy Garu, AMC Thorrur, Rajesh Naik Garu, Sarpanch Thorrur, 100 smiles Advisors Panja Kalpana &amp; Chittimalla Mahesh Garu, school staff, students and villagers, we have provided them with computer lab, science lab, Book library, sports material. `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "school children",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "100 smiles team , staff, guests in digital classroom",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "100 smiles member vijay speech in meeting",
      }
    ]
  }

  public getHaripiralaProjectImages() {
    let imgPath = "assets/images/Haripirala/";
    this.title = `Project 13: Zilla Parishad High School Haripirala, Mahabubabad District.`;
    this.memory = "In the Memory of Chityala Ailamma Garu";
    this.description = `March 11, 2018<br>
        Hyderabad, India<br><br>
        This school is located 207km far away from Hyderabad which has a strength of 460. Our fifth project in collaboration with Global Hunt foundation. 100 Smiles visited the school and researched about their needs and approached Global Hunt foundation advisor Dr. Sharma Garu.
        <br><br>
        We received 100% financial support from Global Hunt team for this school. On July 20th 2018 we visited the school and in the presence Dr Jayapraksah Narayan IAS, Rajarathnam Garu DSP Thorrur, Sreekanth Reddy Maram reddy Garu, PAC India Founder, Bandaru Ram mohan Rao Garu,Loksatta Andhra &amp; Telangana President ,Dasari Mallaiah Garu, Freedom Fighter, Babu Garu , MPDO, Thorrur, Karne Somaiah Garu, MPP Thorrur, Mahankali buchaiah Garu, MEO Thorrur, Narendar reddy Garu, AMC Thorrur, Rajesh Naik Garu, Sarpanch Thorrur, 100 smiles Advisors Panja Kalpana &amp; Chittimalla Mahesh Garu, school staff, students and villagers, we have provided them with computer lab, science lab, Book library, sports material. `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      },
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "school children",
      },
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "100 smiles team , staff, guests in digital classroom",
      },
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "100 smiles member vijay speech in meeting",
      }
    ]
  }

  public getLingamPallyProjectImages() {
    let imgPath = "assets/images/Lingampally/";
    this.title = `Project 14: Mandal parishad primary School, Lingampally Thanda, Medak District`;
    this.memory = "In the Memory of Vavilala Gopalakrishnayya Garu";
    this.description = `March 11, 2018<br>
        Hyderabad, India<br><br>
        100 Smiles in collaboration with Aspire Systems and VNRSF adopted this school in lingampally Thanda which is 90km away from Hyderabad.
        <br><br>
        On 28th July 2018 we have visited the school and provided them with RO Water purifier school kit (books and stationary) and also with the help of VNRSF distributed recycled notebooks to children. After the event we personally interacted with villagers and motivated them to join their children in schools and helped them in understanding how important is education and the paths that are available after schooling to choose.`
    this.galleryData = [
      {
        imageUrl: imgPath + "1.png",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "2.png",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "3.png",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "4.png",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "5.png",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "6.png",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
    console.log(this.galleryData[0])
  }

  public getKondurProjectImages() {
    let imgPath = "assets/images/Kondur/";
    this.title = `Project 15: Mandal Parishad Primary School Kondur, Rayaparti Mandal, Warangal Rural District`;
    this.memory = "In the Memory of Vavilala Gopalakrishnayya Garu";
    this.description = `Feb 02, 2019<br>
      Hyderabad, India<br><br>
      MPPS Kondur is approximately 180kms from Telangana state capital with a strength of 150 children till 5th standard English & telugu Medium.
      <br><br>
      We have worked in the school with the support of our CSR partner Aspire systems Private Limited and with our NGO partner abhaya Foundation
      <br><br>
      We provided Digi classroom & RO water purifier and inaugurated in the presence of our Chief Guest Dayakar Rao Errabelli Panchyathi Raj Minister of Telangana , Gunde Babu Muncipal commissioner Thorrur, Siva krishna abhaya foundation secretary, Srikanth Pathi Kumbagalla CSR Aspire systems, School staff, village representatives , parents, children and 100 smiles Team members.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }

      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "17.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
    console.log(this.galleryData[0])
  }

  public getBadvelProjectImages() {
    let imgPath = "assets/images/Badvel/";
    this.title = `Project 16: ZPHS Badvel, Kadapa District, Andra Pradesh`;
    this.memory = "In the Memory of Umesh Chandra IPS Garu";
    this.description = `Feb 08, 2019<br>
      Hyderabad, India<br><br>
      ZPHS badvel is located In badvel Kadapa district with a strength of 1100 strength, We have worked in the school with the support of our CSR partner Aspire systems Private Limited and with our NGO partner abhaya Foundation &amp; PAC India.
      <br><br>
      We have provided Digital Classroom and inaugurated in the presence of Shri Muktheshwara Rao Garu TTD Director &amp; former collector of Nalgonda, Telangana, 100 Smiles Adviser &amp; PAC NGO founder
Srikanth Reddy Maramreddy, 100 smiles Founder Manoj Chittimalla &amp; General secretary Madhukar
Reddy, school staff , Old students, parents &amp; Children.
      `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "Upper Primary School burgumalla",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: "School Children"
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: "100 smiles member Gayathri inagurating water purifier"
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: "Children welcome speech in the meeting"
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: "100 smiles team interacting with children"
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: "Distributing Sports materials to children"
      }

      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: "Distributing Sports materials to children"
      }
    ]
  }


  public getShaipetProjectImages() {
    let imgPath = "assets/images/Shaipet/";
    this.title = `Project 24: ZPHS Shaipet, Dharmasagar Mandal, Hanumakonda District, Telangana`;
    this.memory = "";
    this.description = `Mar 05, 2022`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "ZPHS Shaipet",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: ""
      }

      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "17.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "18.jpg",
        projectTitle: ""
      }
    ]
  }

  public getVenkatrampuramProjectImages() {
    let imgPath = "assets/images/Venkatrampuram/";
    this.title = `Project 25: ZPHS Venkatrampuram Mahabubabad District, Telangana`;
    this.memory = "";
    this.description = `Apr 10, 2022
    `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "ZPHS Venkatrampuram",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: ""
      }
    ]
  }

  public getMotlathimapuramBayyaramProjectImages() {
    let imgPath = "assets/images/MotlathimapuramBayyaram/";
    this.title = `Project 26: Motlathimapuram Village, Bayyaram Mandal, Mahabubabad District, Telangana`;
    this.memory = "";
    this.description = `Nov 13, 2022`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "ZPHS Motlathimapuram",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: ""
      }
    ]
  }

  getGopalagiriProjectDetails() {
    let imgPath = "assets/images/Gopalagiri/";
    this.title = `Project 27: MPPS Gopalagiri, Mahabubabad District, Telangana`;
    this.memory = "";
    this.description = `Nov 27, 2022 `
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "ZPHS Gopalagiri",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: ""
      }
    ]
  }

  getPadmashalipuramProjectDetails() {
    let imgPath = "assets/images/Padmashalipuram/";
    this.title = `Project 28: ZPHS Padmashalipuram, Rajendranagar Mandal, Hyderabad, Telangana`;
    this.memory = "";
    this.description = `Apr 08, 2023`
    this.galleryData = [
      {
        imageUrl: imgPath + "01.jpg",
        projectTitle: "ZPHS Padmashalipuram",

      },
      {
        imageUrl: imgPath + "02.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "03.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "04.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "05.jpg",
        projectTitle: ""
      },
      {
        imageUrl: imgPath + "06.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "07.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "08.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "09.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "10.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "11.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "12.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "13.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "14.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "15.jpg",
        projectTitle: ""
      }
      ,
      {
        imageUrl: imgPath + "16.jpg",
        projectTitle: ""
      }
    ]
  }
}
