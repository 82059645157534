import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService{

  constructor(httpClient: HttpClient) {
   super();
   this.httpClient = httpClient; 
   this.apiName = 'device'
 }

 public initiatePayment(amount): Observable<any> {

   return this.Execute('paywithpaytm?amount='+amount+'&name=bharath&email=kalakotibharath@gmail.com');
 }
  public joinus(data:any): Observable<any> {
    return this.Execute('joinus', data,'post');
  }
}
